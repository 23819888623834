export const planetChartData = {
  type: 'line',
  data: {
    datasets: [{
      cubicInterpolationMode: 'monotone',
      lineTension: 1,
      backgroundColor: 'rgba(71, 183,132,.5)',
      borderColor: '#47b784',
      data: [
        {x: '2016-12-10', y: 20},
        {x: '2017-01-11', y: 12},
        {x: '2017-02-12', y: 13},
        {x: '2017-03-13', y: 3},
        {x: '2018-10-14', y: 7},
        {x: '2019-04-15', y: 8},
        {x: '2020-08-16', y: 9},
        {x: '2020-10-17', y: 6},
        {x: '2022-11-18', y: 15},
        {x: '2023-12-19', y: 12}]
    }]
  },
  options: {
    scales: {
      xAxes: [{
        type: 'time',
        time: {
            unit: 'month'
        }
    }]
    }
}
}

export default planetChartData
